import { lastValueFrom } from "rxjs";
import { BrandCampaignInvitePartnersService } from "../../../../brand/brand-campaign/brand-campaign-invite-partners/brand-campaign-invite-partners.service";

export class CancelPartnersSheetImportInteractor {
  constructor(
    private readonly invitePartnersService: BrandCampaignInvitePartnersService,
  ) {}

  public async execute(campaignId: number): Promise<void> {
    await lastValueFrom(
      this.invitePartnersService.cancelPartnersSheetImport(campaignId),
    );
  }
}
