import { Mapper } from "../../../../../harmony/core";
import { InvitedPartnerEntity } from "../../data/entities/invited-partner.entity";
import { InvitedPartner } from "../models/invited-partner";
import { PartnersSheetAddress } from "../models/partners-sheet-address";

export class InvitedPartnerEntityToInvitedPartnerMapper
  implements Mapper<InvitedPartnerEntity, InvitedPartner>
{
  public map(from: InvitedPartnerEntity): InvitedPartner {
    return new InvitedPartner(
      from.partnerId,
      from.companyName,
      from.retailerName,
      from.email,
      new PartnersSheetAddress(
        from.address.streetAndNumber,
        from.address.zipCode,
        from.address.city,
        from.address.country,
      ),
      from.status,
      from.isProspect,
      from.uploadedBy,
    );
  }
}
