import { CampaignPartnerStatus } from "../../../../shared/enums/campaign.enums";
import { PartnersSheetAddress } from "./partners-sheet-address";

export class InvitedPartner {
  constructor(
    public readonly partnerId: number,
    public readonly companyName: string,
    public readonly retailerName: string,
    public readonly email: string,
    public readonly address: PartnersSheetAddress,
    public readonly status: CampaignPartnerStatus,
    public readonly isProspect: boolean,
    public readonly uploadedBy: string,
  ) {}
}
