import { lastValueFrom } from "rxjs";
import { BrandCampaignInvitePartnersService } from "../../../../brand/brand-campaign/brand-campaign-invite-partners/brand-campaign-invite-partners.service";

export class ExtendPartnersSheetImportTimeInteractor {
  constructor(
    private readonly invitePartnersService: BrandCampaignInvitePartnersService,
  ) {}

  public async execute(campaignId: number): Promise<Date> {
    return await lastValueFrom(
      this.invitePartnersService.extendPartnersSheetProcessingTime(campaignId),
    );
  }
}
