import { PartnersSheetParsedEntry } from "./partners-sheet-parsed-entry";
import { PartnersSheetParsingError } from "./partners-sheet-parsing-error";

export class PartnersSheetMatches {
  constructor(
    public readonly entries: PartnersSheetParsedEntry[],
    public readonly errors: PartnersSheetParsingError[],
    public readonly expiresAt: Date,
  ) {}
}
