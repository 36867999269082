import {
  PartnerSheetEntryMatch,
  PartnersSheetParsedEntryEntity,
} from "../../data/entities/partners-sheet-matches.entity";
import { MatchCategory } from "./match-category.enum";
import { MatchAddress, PartnersSheetAddress } from "./partners-sheet-address";

export type MatchSelection = "dont-import" | "new-partner" | number;

export class PartnersSheetParsedEntry {
  public selection: MatchSelection = "dont-import";
  public readonly matchCategory: MatchCategory;

  constructor(
    public readonly businessName: string,
    public readonly retailerName: string,
    public readonly address: PartnersSheetAddress,
    public readonly contact: PartnersSheetParsedEntryEntity["contact"],
    public readonly matches: PartnerSheetEntryMatch<MatchAddress>[],
    public readonly lineNumberInDocument: number,
    public readonly socialPalsId?: number,
  ) {
    this.matchCategory = this.getMatchCategory();
  }

  private getMatchCategory(): MatchCategory {
    if (this.matches.length === 0) {
      return MatchCategory.NewPartner;
    } else if (this.matches.some((m) => m.type.startsWith("FULL"))) {
      return MatchCategory.ExactMatch;
    }

    return MatchCategory.Conflict;
  }
}
