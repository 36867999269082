export enum MatchType {
  NoMatch = "NO_MATCH",
  FullMatch = "FULL_MATCH",
  FullMatchNameAddress = "FULL_MATCH_NAME_ADDRESS",
  FullMatchSocialpalsId = "FULL_MATCH_SOCIALPALS_ID",
  PartialMatchName = "PARTIAL_MATCH_NAME",
  PartialMatchAddress = "PARTIAL_MATCH_ADDRESS",
  PartialMatchNameAddress = "PARTIAL_MATCH_NAME_ADDRESS",
}

export interface PartnersSheetAddressEntity {
  streetAndNumber: string;
  zipCode: string;
  city: string;
  country: string;
}

export interface MatchAddressEntity extends PartnersSheetAddressEntity {
  match: boolean;
}

export interface PartnerSheetEntryMatch<T> {
  type: MatchType;
  businessName: string;
  retailerName: string;
  addresses: T[];
  socialPalsID: number;
  email?: string;
}

export interface PartnersSheetParsedEntryEntity {
  businessName: string;
  retailerName: string;
  address: PartnersSheetAddressEntity;
  contact: {
    title: string;
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
  };
  matches: PartnerSheetEntryMatch<MatchAddressEntity>[];
  lineNumberInDocument: number;
  socialPalsID: number | null;
}

export interface ParsingErrorItem {
  column: {
    name: string;
    index: string;
  };
  error: string;
}

export interface PartnersSheetParseError {
  businessName: string | null;
  originalContent: string;
  lineNumberInDocument: number;
  errors: ParsingErrorItem[];
}

export interface PartnersSheetMatchesBody {
  parsedEntries: PartnersSheetParsedEntryEntity[];
  linesWithErrors: PartnersSheetParseError[];
}

export interface PartnersSheetMatchesEntity extends PartnersSheetMatchesBody {
  expiresAt: Date;
}
