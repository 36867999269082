import { lastValueFrom } from "rxjs";
import { BrandCampaignPartnersService } from "../../../../brand/brand-campaign/shared/services/brand-campaign-partners.service";
import { BrandCampaignInvitedPartner } from "../../../../shared/models/brandCampaignInvitedPartner";

export class GetCampaignInvitedPartnersInteractor {
  constructor(
    private readonly brandCampaignPartnersService: BrandCampaignPartnersService,
  ) {}

  public async execute(
    campaignId: number,
  ): Promise<BrandCampaignInvitedPartner[]> {
    return lastValueFrom(
      this.brandCampaignPartnersService.getInvitedPartners(campaignId),
    );
  }
}
