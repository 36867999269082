import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs";
import { Observable } from "rxjs";
import { PartnersToImport } from "../../../features/brand-campaign-partners/domain/interactors/import-partners.interactor";
import { AuthTokenService } from "../../../shared/services/api/auth-token.service";
import { PartnersSheetProcessingProgressEntity } from "../../../features/brand-campaign-partners/data/entities/partners-sheet-processing-progress.entity";
import {
  PartnersSheetMatchesBody,
  PartnersSheetMatchesEntity,
} from "../../../features/brand-campaign-partners/data/entities/partners-sheet-matches.entity";
import { environment } from "../../../../environments/environment";
import { InvitedPartnerEntity } from "../../../features/brand-campaign-partners/data/entities/invited-partner.entity";
import { InvitedPartner } from "../../../features/brand-campaign-partners/domain/models/invited-partner";
import { InvitedPartnerEntityToInvitedPartnerMapper } from "../../../features/brand-campaign-partners/domain/mappers/invited-partner.mapper";
import { BrandCampaign } from "../../../shared/models/brandCampaign";
import { CampaignLandingPageLinkType } from "../../../shared/enums/campaign.enums";

@Injectable()
export class BrandCampaignInvitePartnersService {
  private readonly baseUrl = `${environment.api.baseGatewayUrl}/partner-upload`;
  private readonly options: {
    observe: "response";
    responseType: "json";
    headers: HttpHeaders;
  };

  constructor(
    private readonly authTokenService: AuthTokenService,
    private readonly http: HttpClient,
  ) {
    this.options = {
      observe: "response",
      responseType: "json",
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.authTokenService.userToken}`,
      }),
    };
  }

  public cancelPartnersSheetImport(campaignId: number): Observable<void> {
    return this.http
      .delete<void>(`${this.baseUrl}/${campaignId}`, this.options)
      .pipe(map((response) => response.body!));
  }

  public getInvitedPartners(campaignId: number): Observable<InvitedPartner[]> {
    const mapper = new InvitedPartnerEntityToInvitedPartnerMapper();

    return this.http
      .get<InvitedPartnerEntity[]>(
        `${this.baseUrl}/${campaignId}/import`,
        this.options,
      )
      .pipe(map((response) => response.body!.map((e) => mapper.map(e))));
  }

  public getPartnersSheetProcessingProgress(
    campaignId: number,
  ): Observable<PartnersSheetProcessingProgressEntity> {
    return this.http
      .get<PartnersSheetProcessingProgressEntity>(
        `${this.baseUrl}/${campaignId}/progress`,
        this.options,
      )
      .pipe(map((response) => response.body!));
  }

  public getPartnersSheetProcessingResult(
    campaignId: number,
  ): Observable<PartnersSheetMatchesEntity> {
    return this.http
      .get<PartnersSheetMatchesBody>(
        `${this.baseUrl}/${campaignId}`,
        this.options,
      )
      .pipe(
        map((response) => ({
          ...response.body!,
          expiresAt: new Date(response.headers.get("Expiresat")!),
        })),
      );
  }

  public removeUninvitedById(
    campaignId: number,
    partnerId: number,
  ): Observable<void> {
    return this.http
      .delete(
        `${this.baseUrl}/${campaignId}/partners/${partnerId}`,
        this.options,
      )
      .pipe(map(() => undefined));
  }

  public removeAllUninvited(campaignId: number): Observable<void> {
    return this.http
      .delete(`${this.baseUrl}/${campaignId}/partners`, this.options)
      .pipe(map(() => undefined));
  }

  public extendPartnersSheetProcessingTime(
    campaignId: number,
  ): Observable<Date> {
    return this.http
      .get<PartnersSheetMatchesBody>(
        `${this.baseUrl}/${campaignId}?refresh=true`,
        this.options,
      )
      .pipe(map((response) => new Date(response.headers.get("Expiresat")!)));
  }

  public uploadPartnersSheet(
    campaign: BrandCampaign,
    file: File,
  ): Observable<void> {
    const data = new FormData();
    data.append("file", file);

    const query =
      campaign.landingPageLinkType ===
      CampaignLandingPageLinkType.BrandProvidedPartnerUrl
        ? "?lpUrl=true"
        : "";

    return this.http
      .post(`${this.baseUrl}/${campaign.id}${query}`, data, this.options)
      .pipe(map(() => undefined));
  }

  public importPartners(
    campaignId: number,
    partnersToImport: PartnersToImport,
  ): Observable<void> {
    return this.http
      .post(
        `${this.baseUrl}/${campaignId}/import`,
        partnersToImport,
        this.options,
      )
      .pipe(map(() => undefined));
  }
}
