import { HttpErrorResponse } from "@angular/common/http";
import { lastValueFrom } from "rxjs";
import { NotFoundError } from "../../../../../harmony/core";
import { BrandCampaignInvitePartnersService } from "../../../../brand/brand-campaign/brand-campaign-invite-partners/brand-campaign-invite-partners.service";

export class GetPartnersSheetProcessingProgressInteractor {
  constructor(
    private readonly invitePartnersService: BrandCampaignInvitePartnersService,
  ) {}

  public async execute(campaignId: number): Promise<number> {
    try {
      const res = await lastValueFrom(
        this.invitePartnersService.getPartnersSheetProcessingProgress(
          campaignId,
        ),
      );

      // Clamp to 0.0 - 1.0
      return Math.min(Math.max(res.progressPercentage, 0.0), 1.0) * 100;
    } catch (err) {
      if (err instanceof HttpErrorResponse && err.status === 404) {
        throw new NotFoundError(
          `Partners sheet processing progress for campaign "${campaignId}" not found`,
        );
      }

      throw err;
    }
  }
}
