import { HttpErrorResponse } from "@angular/common/http";
import { lastValueFrom } from "rxjs";
import { BrandCampaignInvitePartnersService } from "../../../../brand/brand-campaign/brand-campaign-invite-partners/brand-campaign-invite-partners.service";
import { BrandCampaign } from "../../../../shared/models/brandCampaign";

export class UploadPartnersSheetError extends Error {
  constructor(
    public readonly type: string,
    public readonly columns?: string[],
  ) {
    super(`Error "${type}" while uploading partners sheet`);
    Object.setPrototypeOf(this, UploadPartnersSheetError.prototype);
    this.name = "Upload Partners Sheet Error";
  }
}

export class UploadPartnersSheetInteractor {
  constructor(
    private readonly invitePartnersService: BrandCampaignInvitePartnersService,
  ) {}

  public async execute(campaign: BrandCampaign, file: File): Promise<void> {
    try {
      await lastValueFrom(
        this.invitePartnersService.uploadPartnersSheet(campaign, file),
      );
    } catch (err) {
      if (err instanceof HttpErrorResponse && err.status === 422) {
        throw new UploadPartnersSheetError(
          err.error.error,
          err.error.details.columns,
        );
      }

      throw err;
    }
  }
}
