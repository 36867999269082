import { ParsingErrorItem } from "../../data/entities/partners-sheet-matches.entity";

export class PartnersSheetParsingError {
  constructor(
    public readonly originalContent: string,
    public readonly lineNumber: number,
    public readonly errors: ParsingErrorItem[],
    public readonly businessName?: string,
  ) {}
}
