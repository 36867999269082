export class PartnersSheetAddress {
  public readonly full: string;

  constructor(
    public readonly streetAndNumber: string,
    public readonly zipCode: string,
    public readonly city: string,
    public readonly country: string,
  ) {
    this.streetAndNumber = (streetAndNumber ?? "").trim();
    this.zipCode = (zipCode ?? "").trim();
    this.city = (city ?? "").trim();
    this.country = (country ?? "").trim();

    this.full = [
      this.streetAndNumber,
      `${this.zipCode} ${this.city}`,
      this.country,
    ]
      .map((part) => part.trim())
      .filter((part) => part !== "")
      .join(", ");
  }
}

export class MatchAddress extends PartnersSheetAddress {
  constructor(
    public readonly streetAndNumber: string,
    public readonly zipCode: string,
    public readonly city: string,
    public readonly country: string,
    public readonly match: boolean,
  ) {
    super(streetAndNumber, zipCode, city, country);
  }
}
