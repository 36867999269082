import { lastValueFrom } from "rxjs";
import { BrandCampaignInvitePartnersService } from "../../../../brand/brand-campaign/brand-campaign-invite-partners/brand-campaign-invite-partners.service";
import { PartnersSheetMatchesEntityToPartnersSheetMatchesMapper } from "../mappers/partners-sheet-matches.mapper";
import { PartnersSheetMatches } from "../models/partners-sheet-matches";

export class GetPartnersSheetProcessingResultInteractor {
  private readonly mapper =
    new PartnersSheetMatchesEntityToPartnersSheetMatchesMapper();

  constructor(
    private readonly invitePartnersService: BrandCampaignInvitePartnersService,
  ) {}

  public async execute(campaignId: number): Promise<PartnersSheetMatches> {
    const result = this.mapper.map(
      await lastValueFrom(
        this.invitePartnersService.getPartnersSheetProcessingResult(campaignId),
      ),
    );

    // Sort errors by line number
    result.errors.sort((a, b) => a.lineNumber - b.lineNumber);

    return result;
  }
}
