import { lastValueFrom } from "rxjs";
import { BrandCampaignInvitePartnersService } from "../../../../brand/brand-campaign/brand-campaign-invite-partners/brand-campaign-invite-partners.service";

export interface PartnersToImport {
  /** List of row indexes from the partners sheet */
  prospectPartnerIds: number[];
  /** List of socialPALS ids */
  existingPartnerIds: number[];
}

export class ImportPartnersInteractor {
  constructor(
    private readonly invitePartnersService: BrandCampaignInvitePartnersService,
  ) {}

  public async execute(
    campaignId: number,
    partnersToImport: PartnersToImport,
  ): Promise<void> {
    await lastValueFrom(
      this.invitePartnersService.importPartners(campaignId, partnersToImport),
    );
  }
}
