import { Mapper } from "../../../../../harmony/core";
import {
  MatchType,
  PartnersSheetMatchesEntity,
  PartnersSheetParsedEntryEntity,
} from "../../data/entities/partners-sheet-matches.entity";
import {
  MatchAddress,
  PartnersSheetAddress,
} from "../models/partners-sheet-address";
import { PartnersSheetMatches } from "../models/partners-sheet-matches";
import { PartnersSheetParsedEntry } from "../models/partners-sheet-parsed-entry";
import { PartnersSheetParsingError } from "../models/partners-sheet-parsing-error";

export class PartnersSheetMatchesEntityToPartnersSheetMatchesMapper
  implements Mapper<PartnersSheetMatchesEntity, PartnersSheetMatches>
{
  public map(from: PartnersSheetMatchesEntity): PartnersSheetMatches {
    return new PartnersSheetMatches(
      from.parsedEntries.map(
        (entry) =>
          new PartnersSheetParsedEntry(
            entry.businessName,
            entry.retailerName,
            new PartnersSheetAddress(
              entry.address.streetAndNumber,
              entry.address.zipCode,
              entry.address.city,
              entry.address.country,
            ),
            entry.contact,
            this.mapMatches(entry.matches),
            entry.lineNumberInDocument,
            entry.socialPalsID ?? undefined,
          ),
      ),
      this.mapErrors(from.linesWithErrors),
      from.expiresAt,
    );
  }

  private mapErrors(
    errors: PartnersSheetMatchesEntity["linesWithErrors"],
  ): PartnersSheetParsingError[] {
    return errors.map(
      (err) =>
        new PartnersSheetParsingError(
          err.originalContent,
          err.lineNumberInDocument,
          err.errors,
          err.businessName ?? undefined,
        ),
    );
  }

  private mapMatches(
    matches: PartnersSheetParsedEntryEntity["matches"],
  ): PartnersSheetParsedEntry["matches"] {
    const FULL_MATCH_PREFIX = "FULL";
    const hasFullMatch = matches.some((m) =>
      m.type.startsWith(FULL_MATCH_PREFIX),
    );

    return matches
      .filter(
        (match) =>
          match.type !== MatchType.NoMatch &&
          (hasFullMatch ? match.type.startsWith(FULL_MATCH_PREFIX) : true),
      )
      .map((match) => ({
        type: match.type,
        businessName: match.businessName,
        retailerName: match.retailerName,
        addresses: (match.addresses ?? []).map(
          (matchAddress) =>
            new MatchAddress(
              matchAddress.streetAndNumber,
              matchAddress.zipCode,
              matchAddress.city,
              matchAddress.country,
              matchAddress.match,
            ),
        ),
        email: match.email,
        socialPalsID: match.socialPalsID,
      }));
  }
}
