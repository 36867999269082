import { NotFoundError } from "../../../../../harmony/core";
import { PartnersSheetStatus } from "../models/partners-sheet-status.enum";
import { GetPartnersSheetProcessingProgressInteractor } from "./get-partners-sheet-processing-progress.interactor";

export interface PartnersSheetState {
  status: PartnersSheetStatus;
  progress: number;
}

export class GetPartnersSheetStateInteractor {
  constructor(
    private readonly getPartnersSheetProcessingProgress: GetPartnersSheetProcessingProgressInteractor,
  ) {}

  public async execute(campaignId: number): Promise<PartnersSheetState> {
    try {
      const progress =
        await this.getPartnersSheetProcessingProgress.execute(campaignId);

      const status =
        progress >= 100.0
          ? PartnersSheetStatus.Matching
          : PartnersSheetStatus.Processing;

      return {
        status: status,
        progress: progress,
      };
    } catch (err) {
      if (err instanceof NotFoundError) {
        return {
          status: PartnersSheetStatus.AcceptsFile,
          progress: 0,
        };
      }

      throw err;
    }
  }
}
